import { useState } from 'react'

import { DeleteOutlined, ExclamationCircleOutlined, TeamOutlined } from '@ant-design/icons'
import { Table, Button, Space, Row, Col, App, Card, Typography } from 'antd'
import { Link, useParams } from 'react-router-dom'

import { useDebounce } from '../../hooks/useDebounce'
import { deleteAssistantFromEvent } from '../../services/eventService'
import { deleteUserOrganization } from '../../services/organizationService'
import { useUser, useUserOrganizations, useUsers } from '../../services/userService'

const UserCompany = () => {
  const { modal } = App.useApp()
  const { userId } = useParams()
  const { data: organizations, isLoading, mutate } = useUserOrganizations(userId)
  const { data: user, isUserLoading, mutateUser } = useUser(userId)
  const [searchText, setSearchText] = useState('')
  const debouncedSearchTerm = useDebounce(searchText)

  const columns = [
    {
      title: '#',
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (value, record) => <Link to={`/organizations/${record.organizationId}`}>{record.Organization.name}</Link>,
    },
    {
      title: 'Role',
      dataIndex: 'userRole',
      render: (value, record) => <span>{record.userRole ? record.userRole : 'Event Assistant'}</span>,
    },
    {
      title: 'Actions',
      align: 'center',
      fixed: 'right',
      width: 102,
      render: (text, record) => (
        <Row justify="end">
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => (record.userRole ? openDeleteModal(record) : openDeleteUserEventModal(record))}
            danger
          />
        </Row>
      ),
    },
  ]

  const openDeleteModal = (organization) => {
    modal.confirm({
      title: `Unlink ${user.firstName} ${user.lastName} from this company: ${organization.Organization.name}`,
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to unlink this user from the company?',
      okButtonProps: { danger: true },
      onOk: async () => {
        await deleteUserOrganization(organization.id)
        mutate(organizations.filter((u) => u.id !== organization.id))
      },
    })
  }

  const openDeleteUserEventModal = (event) => {
    modal.confirm({
      title: `Unlink ${user.firstName} ${user.lastName} from this event`,
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to unlink this user from the company event?',
      okButtonProps: { danger: true },
      onOk: async () => {
        await deleteAssistantFromEvent(event.userId, event.eventId)
        mutate(organizations.filter((u) => u.id !== event.id))
      },
    })
  }

  const dataSource = isLoading ? [] : organizations.map((u) => ({ ...u, key: u.id }))
  const filteredData = debouncedSearchTerm
    ? dataSource.filter((data) => JSON.stringify(data).toLowerCase().includes(debouncedSearchTerm.toLowerCase()))
    : dataSource

  return (
    <div style={{ padding: '1rem' }}>
      <Space style={{ width: '100%' }} direction="vertical" size={16}>
        <Card loading={isUserLoading} title="User">
          <Row gutter={16} align="middle">
            <Col span={6} className="text-right">
              <Typography>Name:</Typography>
            </Col>
            <Col span={18}>
              <Row align="middle" gutter={12}>
                <Col>
                  <Typography>
                    {user?.firstName} {user?.lastName}
                  </Typography>
                </Col>
              </Row>
            </Col>
            <Col span={6} className="text-right">
              <Typography>Related companies:</Typography>
            </Col>
            <Col span={18}>
              <Typography>
                {dataSource?.length} {dataSource?.length > 1 ? 'companies' : 'company'}
              </Typography>
            </Col>
          </Row>
        </Card>
        <Card loading={isUserLoading} title="User Companies">
          <Table loading={isLoading} columns={columns} dataSource={filteredData} pagination={{ defaultPageSize: 10 }} />
        </Card>
      </Space>
    </div>
  )
}

export default UserCompany
