import { useState } from 'react'

import { SearchOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Table, Input, Button, Space, Row, Col, App } from 'antd'
import { Link } from 'react-router-dom'

import { useDebounce } from '../../hooks/useDebounce'
import { deleteUserEventsOrganizations, useUsers } from '../../services/userService'

const Users = () => {
  const { modal } = App.useApp()
  const { data: users, isLoading, mutate } = useUsers()
  const [searchText, setSearchText] = useState('')
  const debouncedSearchTerm = useDebounce(searchText)

  const columns = [
    {
      title: '#',
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (value, record) => <Link to={`/users/${record.id}`}>{record.fullName}</Link>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (value, record) => record.email,
    },
    {
      title: 'Actions',
      align: 'center',
      fixed: 'right',
      width: 102,
      render: (text, record) => (
        <Row justify="end">
          <Button type="text" icon={<DeleteOutlined />} onClick={() => openDeleteModal(record)} danger />
        </Row>
      ),
    },
  ]

  const openDeleteModal = (user) => {
    modal.confirm({
      title: `Delete ${user.fullName}`,
      icon: <ExclamationCircleOutlined />,
      content:
        'Once a user is deleted, he will no longer be able to access the site. Are you sure you want to proceed with their removal?',
      okButtonProps: { danger: true },
      onOk: async () => {
        await deleteUserEventsOrganizations(user.id)
        mutate(users.filter((u) => u.id !== user.id))
      },
    })
  }

  const dataSource = isLoading ? [] : users.map((u) => ({ ...u, key: u.id, fullName: `${u.firstName} ${u.lastName}` }))
  const filteredData = debouncedSearchTerm
    ? dataSource.filter((data) => JSON.stringify(data).toLowerCase().includes(debouncedSearchTerm.toLowerCase()))
    : dataSource

  return (
    <div style={{ padding: '1rem' }}>
      <Space style={{ width: '100%' }} direction="vertical" size={16}>
        <Row justify="end" gutter={16}>
          <Col flex="auto">
            <Input
              placeholder="Find user by name or email"
              prefix={<SearchOutlined />}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Col>
        </Row>
        <Table loading={isLoading} columns={columns} dataSource={filteredData} pagination={{ defaultPageSize: 10 }} />
      </Space>
    </div>
  )
}

export default Users
