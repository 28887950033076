import { useProfile } from '../services/profileService'

export default function GuardedContent({ roles, children }) {
  const { data, isLoading } = useProfile()

  if (isLoading) return null

  const orgRoles = data?.organizations.map((org) => org.userRole)
  const hasCommonItem = roles.some((role) => orgRoles.includes(role))
  if (!hasCommonItem) return null

  return children
}

export function isGuarded(roles) {
  const { data, isLoading } = useProfile()

  if (isLoading) return false

  let customData = data
  if (data.data) {
    customData = data.data
  }
  const orgRoles = customData?.organizations?.map((org) => org.userRole)
  const hasCommonItem = roles?.some((role) => orgRoles?.includes(role))
  if (!hasCommonItem) return null

  return true
}
