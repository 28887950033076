import loadable from '@loadable/component'
import { App as AntApp } from 'antd'
import { Provider } from 'react-redux'
import { Route, Navigate, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'

import FreeTrialLimited from './components/FreeTrialLimited'
import PermissionGuard from './components/PermissionGuard'
import { ROLE } from './config/constants'
import { AuthLayout } from './containers/AuthLayout'
import { EventLayout } from './containers/EventLayout'
import { PortalLayout } from './containers/PortalLayout'
import Users from './containers/Users'
import UserCompany from './containers/Users/UserCompany'
import { store } from './store'
import { getPriorRoles } from './utils/getPriorRoles'

// Page containers
const Account = loadable(() => import('./containers/Account'))
const AllEvents = loadable(() => import('./containers/AllEvents'))
const EventAssets = loadable(() => import('./containers/EventAssets'))
const EventAdmin = loadable(() => import('./containers/EventAdmin'))
const EventBreakouts = loadable(() => import('./containers/EventBreakouts'))
const EventCustomers = loadable(() => import('./containers/EventCustomers'))
const EventCampaigns = loadable(() => import('./containers/EventCampaigns'))
const EventCampaignEditor = loadable(() => import('./containers/EventCampaignEditor'))
const EventDashboard = loadable(() => import('./containers/EventDashboard'))
const EventDomains = loadable(() => import('./containers/EventDomains'))
const EventEmailTemplates = loadable(() => import('./containers/EventEmailTemplates'))
const EventEmailTemplateEditor = loadable(() => import('./containers/EmailTemplateEditor'))
const EventGeneral = loadable(() => import('./containers/EventGeneral'))
const EventGroups = loadable(() => import('./containers/EventGroups'))
const EventLayoutEditor = loadable(() => import('./containers/EventLayoutEditor'))
const EventNotifications = loadable(() => import('./containers/EventNotifications'))
const EventPages = loadable(() => import('./containers/EventPages'))
const EventPageEditor = loadable(() => import('./containers/EventPageEditor'))
const EventPayments = loadable(() => import('./containers/EventPayments'))
const EventReports = loadable(() => import('./containers/EventReports'))
const EventSessions = loadable(() => import('./containers/EventSessions'))
const EventSpeakers = loadable(() => import('./containers/EventSpeakers'))
const EventTechSupport = loadable(() => import('./containers/EventTechSupport'))
const EventVendors = loadable(() => import('./containers/EventVendors'))
const ForgotPassword = loadable(() => import('./containers/ForgotPassword'))
const Login = loadable(() => import('./containers/Login'))
const NotEnoughPermission = loadable(() => import('./containers/NotEnoughPermission'))
const Organization = loadable(() => import('./containers/Organization'))
const Organizations = loadable(() => import('./containers/Organizations'))
const OrganizationUser = loadable(() => import('./containers/OrganizationUser'))
const Register = loadable(() => import('./containers/Register'))
const ResetPassword = loadable(() => import('./containers/ResetPassword'))
const VerifyEmail = loadable(() => import('./containers/VerifyEmail'))

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="verify-email" element={<VerifyEmail />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
      </Route>
      <Route element={<PortalLayout />}>
        <Route path="home" element={<AllEvents />} />
        <Route path="events" element={<EventAdmin />} />
        <Route path="event/:eventId" element={<EventLayout />}>
          <Route path="" element={<Navigate to="dashboard" />} />
          <Route path="assets" element={<EventAssets />} />
          <Route path="breakouts" element={<EventBreakouts />} />
          <Route element={<FreeTrialLimited />}>
            <Route path="campaigns" element={<EventCampaigns />} />
            <Route path="campaigns/:campaignId" element={<EventCampaignEditor />} />

            <Route path="email-templates" element={<EventEmailTemplates />} />
            <Route path="email-templates/:emailTemplateId" element={<EventEmailTemplateEditor />} />
          </Route>
          <Route path="customers" element={<EventCustomers />} />
          <Route path="dashboard" element={<EventDashboard />} />
          <Route path="domains" element={<EventDomains />} />
          <Route path="general" element={<EventGeneral />} />
          <Route path="groups" element={<EventGroups />} />
          <Route path="layout" element={<EventLayoutEditor />} />
          <Route path="notifications" element={<EventNotifications />} />
          <Route path="pages" element={<EventPages />} />
          <Route path="pages/:pageId" element={<EventPageEditor />} />
          <Route path="payments" element={<EventPayments />} />
          <Route path="reports" element={<EventReports />} />
          <Route path="sessions" element={<EventSessions />} />
          <Route path="speakers" element={<EventSpeakers />} />
          <Route path="tech-support" element={<EventTechSupport />} />
          <Route path="vendors" element={<EventVendors />} />
        </Route>
        <Route path="account" element={<Account />} />
        <Route path="organizations" element={<Organizations />} />
        <Route path="organizations/:organizationId" element={<Organization />} />
        <Route path="organizations/:organizationId/users/:userId" element={<OrganizationUser />} />
        <Route path="users" element={<Users />} />
        <Route path="users/:userId" element={<UserCompany />} />
      </Route>
      <Route path="not-enough-permission" element={<NotEnoughPermission />} />
    </Route>,
  ),
)

function App() {
  return (
    <Provider store={store}>
      <AntApp>
        <RouterProvider router={router} />
      </AntApp>
    </Provider>
  )
}

export default App
