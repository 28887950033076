import { useState } from 'react'

import {
  AppstoreAddOutlined,
  BellOutlined,
  ContactsOutlined,
  CreditCardOutlined,
  CustomerServiceOutlined,
  DesktopOutlined,
  FileOutlined,
  FolderOpenOutlined,
  HomeOutlined,
  InboxOutlined,
  LayoutOutlined,
  MailOutlined,
  PieChartOutlined,
  SnippetsOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import { App, Layout, Menu, Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import ContactUsModal from '../../components/ContactUsModal'
import { contactUs } from '../../store/Auth'

const { Sider } = Layout

const SideBar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { eventId } = useParams()
  const [collapsed, setCollapsed] = useState(false)
  const [isHelpModalVisible, setIsHelpModalVisible] = useState(false)
  const dispatch = useDispatch()

  const { notification } = App.useApp()

  const onClose = () => {
    setIsHelpModalVisible(false)
  }

  const handleContactUsSave = async (values) => {
    try {
      await dispatch(contactUs(values))
      notification.success({
        message: 'Contact Us Email Sent',
      })
      setIsHelpModalVisible(false)
    } catch (e) {
      console.log('Failed to request password reset', e)
    }
  }

  const toggleSidebar = () => setCollapsed((prev) => !prev)

  const paths = location.pathname.split('/')
  const activePath = paths[paths.length - 1]

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={toggleSidebar}>
      {/* TODO: these items should be shown/hiden based on permissions user have to event.
                AMS, Company members can access pretty much everything. but event assistants not */}
      <Menu
        theme="dark"
        defaultSelectedKeys={[activePath]}
        items={[
          {
            key: 'all-events',
            icon: <HomeOutlined />,
            onClick: () => navigate('/home'),
            label: 'All Events',
          },
          {
            type: 'divider',
          },
          {
            key: 'dashboard',
            icon: <PieChartOutlined />,
            onClick: () => navigate('dashboard'),
            label: 'Dashboard',
          },
          {
            key: 'general',
            icon: <DesktopOutlined />,
            onClick: () => navigate('general'),
            label: 'General',
          },
          {
            key: 'layout',
            icon: <LayoutOutlined />,
            onClick: () => navigate('layout'),
            label: 'Layout',
          },
          {
            key: 'pages',
            icon: <FileOutlined />,
            onClick: () => navigate('pages'),
            label: 'Pages',
          },
          {
            key: 'assets',
            icon: <FolderOpenOutlined />,
            onClick: () => navigate('assets'),
            label: 'Assets',
          },
          // {
          //   key: 'payments',
          //   icon: <CreditCardOutlined />,
          //   onClick: () => navigate('payments'),
          //   label: 'Payments',
          // },
          {
            type: 'divider',
          },
          {
            key: 'templates',
            icon: <MailOutlined />,
            onClick: () => navigate('email-templates'),
            label: 'Email Manager',
          },
          {
            key: 'campaigns',
            icon: <InboxOutlined />,
            onClick: () => navigate('campaigns'),
            label: 'Email Campaigns',
          },
          {
            type: 'divider',
          },
          {
            key: 'tech-support',
            icon: <CustomerServiceOutlined />,
            onClick: () => navigate('tech-support'),
            label: 'Tech Support',
          },
          {
            key: 'notifications',
            icon: <BellOutlined />,
            onClick: () => navigate('notifications'),
            label: 'Notifications',
          },
          {
            type: 'divider',
          },
          {
            key: 'customers',
            icon: <TeamOutlined />,
            onClick: () => navigate('customers'),
            label: 'Attendees',
          },
          {
            key: 'groups',
            icon: <AppstoreAddOutlined />,
            onClick: () => navigate('groups'),
            label: 'Groups',
          },
          {
            key: 'reports',
            icon: <SnippetsOutlined />,
            onClick: () => navigate('reports'),
            label: 'Reports',
          },
          {
            type: 'divider',
          },
          {
            key: 'help',
            icon: <ContactsOutlined />,
            onClick: () => setIsHelpModalVisible(true),
            label: 'Contact Us',
          },
        ]}
      />
      <ContactUsModal visible={isHelpModalVisible} onClose={onClose} handleSave={handleContactUsSave} />
    </Sider>
  )
}

export default SideBar
