import { useState } from 'react'

import { App, Button, Layout, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import PortalHeader from './PortalHeader'
import LogoImg from '../../assets/imgs/hybridley-support.png'
import ContactUsModal from '../../components/ContactUsModal'
import { useProfile } from '../../services/profileService'
import { contactUs } from '../../store/Auth'
import { selectIsAuthenticated } from '../../store/Auth/selectors'
import './styles.less'

const { Content } = Layout

const PortalLayout = () => {
  const location = useLocation()
  const { isLoading, isLoaded, error } = useProfile()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const [isHelpModalVisible, setIsHelpModalVisible] = useState(false)
  const dispatch = useDispatch()

  const { notification } = App.useApp()

  const onClose = () => {
    setIsHelpModalVisible(false)
  }

  const handleContactUsSave = async (values) => {
    try {
      await dispatch(contactUs(values))
      notification.success({
        message: 'Contact Us Email Sent',
      })
      setIsHelpModalVisible(false)
    } catch (e) {
      console.log('Failed to request password reset', e)
    }
  }

  if (isLoading && !isLoaded) {
    return <div>Loading...</div>
  }

  if (!isAuthenticated || error) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  return (
    <Layout className="portal-layout">
      <PortalHeader />
      <Content className="portal-content">
        <Outlet />

        <div className="help-button" onClick={() => setIsHelpModalVisible(true)}></div>
        <ContactUsModal visible={isHelpModalVisible} onClose={onClose} handleSave={handleContactUsSave} />
      </Content>
    </Layout>
  )
}

export default PortalLayout
