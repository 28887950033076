import { useState } from 'react'

import { App, Card } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import ContactUsModal from '../../components/ContactUsModal'
import { contactUs } from '../../store/Auth'
import { selectIsAuthenticated } from '../../store/Auth/selectors'

import './styles.less'

const AuthLayout = () => {
  const { state } = useLocation()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const [isHelpModalVisible, setIsHelpModalVisible] = useState(false)
  const dispatch = useDispatch()

  const { notification } = App.useApp()

  const onClose = () => {
    setIsHelpModalVisible(false)
  }

  const handleContactUsSave = async (values) => {
    try {
      await dispatch(contactUs(values))
      notification.success({
        message: 'Contact Us Email Sent',
      })
      setIsHelpModalVisible(false)
    } catch (e) {
      console.log('Failed to request password reset', e)
    }
  }

  if (isAuthenticated) {
    return <Navigate to={state?.from?.pathname || '/home'} />
  }

  return (
    <div className="auth-layout">
      <Card className="form-container">
        <Outlet />
        <div className="help-button" onClick={() => setIsHelpModalVisible(true)}></div>
        <ContactUsModal visible={isHelpModalVisible} onClose={onClose} handleSave={handleContactUsSave} />
      </Card>
    </div>
  )
}

export default AuthLayout
