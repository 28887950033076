import api, { useAPI } from './api'

// Get a user record
export function useUser(userId) {
  return useAPI(`/users/${userId}`)
}

// Get a user record
export function useUsers() {
  return useAPI(`/users`)
}

// Create a user record
export function createUser(user) {
  return api.post(`/users`, user)
}

// Update a user record
export function updateUser(userId, user) {
  return api.put(`/users/${userId}`, user)
}

// Delete a user record
export function deleteUser(userId) {
  return api.delete(`/users/${userId}`)
}

// Delete a user, related event/organization records
export function deleteUserEventsOrganizations(userId) {
  return api.delete(`/users/${userId}/event-organization`)
}

// Request email verification for the authenticated user
export function requestVerification(user) {
  return api.get(`/users/verify`)
}

// Mark a user's idToken as expired
export function logout(userId) {
  return api.get(`/users/${userId}logout`)
}

// Change user password
export function changeUserPassword(userId, payload) {
  return api.post(`/users/${userId}/change-password`, payload)
}

// Get list of events user have access to under an organization
export function useUserOrgEvents(userId, organizationId) {
  return useAPI(`organizations/${organizationId}/assistants/${userId}/events`)
}

// Update event_permissions user have to an event
export function updateUserEventPermissions(userEventId, permissions) {
  return api.put(`/users/${userEventId}/permissions`, permissions)
}

// Update company member
export function updateOrganizationMember(userOrganizationId, payload) {
  return api.put(`organizations/userOrganizations/${userOrganizationId}`, payload)
}

// get user organizations
export function useUserOrganizations(userId) {
  return useAPI(`/users/${userId}/user-organizations`)
}
