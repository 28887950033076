import { useEffect, useState } from 'react'

import { Form, Input, Modal, Space, Typography } from 'antd'

const ContactUsModal = ({ visible, handleSave, onClose }) => {
  const [form] = Form.useForm()

  const onFinish = () => {
    form.submit()
  }

  return (
    <Modal title="Hybridley Tech Support" open={visible} onOk={onFinish} onCancel={onClose}>
      <Form
        name="contactUs"
        initialValues={{}}
        layout="vertical"
        onFinish={handleSave}
        autoComplete="off"
        form={form}
        style={{ paddingTop: 30, paddingBottom: 10 }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input your name!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Please input your valid email!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Phone #" name="phonenumber">
          <Input />
        </Form.Item>

        <Form.Item label="Event Name" name="eventName">
          <Input />
        </Form.Item>

        <Form.Item
          label="What can we assist you with?"
          name="subject"
          rules={[
            {
              required: true,
              message: 'Please input your needs!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Please give as many details as possible:"
          name="details"
          rules={[
            {
              required: true,
              message: 'Please input your details!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        {/* <Form.Item className="text-center">
        <Button type="primary" htmlType="submit">
          Register
        </Button>
      </Form.Item>
      <Form.Item className="text-center">
        Already have an account? <Link to="/login">Login here</Link>.
      </Form.Item> */}
      </Form>
    </Modal>
  )
}

export default ContactUsModal
